import { useEffect, useState } from "react";

// nfts images
import nft4 from "../assets/images/blog_images/4n.jpg";
import nft3 from "../assets/images/blog_images/3n.jpg";
import nft2 from "../assets/images/blog_images/2n.jpg";
import nft1 from "../assets/images/blog_images/1n.jpg";
import nft5 from "../assets/images/blog_images/5n.jpg";
import nft6 from "../assets/images/blog_images/6n.jpg";
import nft7 from "../assets/images/blog_images/7n.jpg";
import nft8 from "../assets/images/blog_images/8n.jpg";
import nft9 from "../assets/images/blog_images/9n.jpg";
import nft10 from "../assets/images/blog_images/10n.jpg";
import nft11 from "../assets/images/blog_images/11n.jpg";
import nft12 from "../assets/images/blog_images/12n.jpg";
import nft13 from "../assets/images/blog_images/13n.jpg";
import nft14 from "../assets/images/blog_images/14n.jpg";
import nft15 from "../assets/images/blog_images/15n.jpg";
import nft16 from "../assets/images/blog_images/16n.jpg";
import nft17 from "../assets/images/blog_images/17n.png";


import img1 from "../assets/images/slider/brand-1.png";
import img2 from "../assets/images/slider/brand-2.png";
import img3 from "../assets/images/slider/brand-3.png";
import img4 from "../assets/images/slider/brand-4.png";
import img5 from "../assets/images/slider/brand-5.png";
//  icon use as img here
import icon from "../assets/images/icons/icon-1.svg";
import icon1 from "../assets/images/icons/icon-2.svg";
import icon2 from "../assets/images/icons/icon-3.svg";
import icon3 from "../assets/images/icons/icon-4.svg";
import icon4 from "../assets/images/icons/icon-5.svg";
import icon5 from "../assets/images/icons/icon-6.svg";
// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import { CgNotes } from "react-icons/cg";
import { FaBlogger, FaRegUser, FaAward, FaEnvira  } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";


//nfts url
var nft4L = "1013";
var nft3L = "1016";
var nft2L = "1018";
var nft1L = "1017";
var nft5L = "1012";
var nft6L = "1011";
var nft7L = "1010";
var nft8L = "1009";
var nft9L = "862";
var nft10L = "5048";
var nft11L = "1991";
var nft12L = "603";
var nft13L = "3864";
var nft14L = "934";
var nft15L = "4369";
var nft16L = "4831";
var nft17L = "3483";

//nfts contract
var lazy = "0x8943c7bac1914c9a7aba750bf2b6b09fd21037e0/";
var wfv = "0xc0423cd6d1e19d21f1f031895867e4ca36a14af3/";
var fenix = "0x4913b8eb5ad9b5ba726a42e603c65fe1bd9be3cb/";


//nfts collections
var lazyCollection = "Lazy Lions";
var lazyCollectionDesc = "Why do they give us so many words for this description? We're lazy."
var lazyKey = lazyCollection;

var fenixCollection = "FENIX - Illustramanda";
var fenixCollectionDesc = "The first and only, official generative art collection of 5,252 unique hand drawn pieces by @illustramanda the creator of Karma and renowned NFT artist. Celebrate the beauty and diversity of women all around the world!"
var fenixKey = fenixCollection;

var wfvCollection = "Women From Venus";
var wfvCollectionDesc = "What started out as creating more women-represented art turned into a movement! 5,555 randomly generated unique Women from Venus living on the blockchain are ready to travel throughout the metaverse. WFV is a welcoming, transparent, and organic community passionate about making a positive difference in the world. Focused on creating a safe welcoming space to onboard women into Web3.";
var wfvKey = wfvCollection;

var openseaLink = "https://opensea.io/assets/ethereum/";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.key === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    {
      id: "0",
      key: lazyKey,
      tag: nft17L,
      title: lazyCollection + "[" + nft17L + "]",
      img: nft17,
      imgSmall: nft17,
      bg: "#ddd",
      client: lazyCollection,
      langages: nft17L,
      link: openseaLink + lazy + nft17L,
      linkText: "opensea.io",
      description: lazyCollectionDesc
    },
    {
      id: "1",
      key: fenixKey,
      tag: nft1L,
      title: fenixCollection + "[" + nft1L + "]",
      img: nft1,
      imgSmall: nft1,
      bg: "#ddd",
      client: fenixCollection,
      langages: nft1L,
      link: openseaLink + fenix + nft1L,
      linkText: "opensea.io",
      description: fenixCollectionDesc
    },
    {
      id: "2",
      key: fenixKey,
      tag: nft2L,
      title: fenixCollection + "[" + nft2L + "]",
      img: nft2,
      imgSmall: nft2,
      bg: "#ddd",
      client: fenixCollection,
      langages: nft2L,
      link: openseaLink + fenix + nft2L,
      linkText: "opensea.io",
      description: fenixCollectionDesc
    },
    {
      id: "3",
      key: fenixKey,
      tag: nft3L,
      title: fenixCollection + "[" + nft3L + "]",
      img: nft3,
      imgSmall: nft3,
      bg: "#ddd",
      client: fenixCollection,
      langages: nft3L,
      link: openseaLink + fenix + nft3L,
      linkText: "opensea.io",
      description: fenixCollectionDesc
    },
    {
      id: "4",
      key: fenixKey,
      tag: nft4L,
      title: fenixCollection + "[" + nft4L + "]",
      img: nft4,
      imgSmall: nft4,
      bg: "#ddd",
      client: fenixCollection,
      langages: nft4L,
      link: openseaLink + fenix + nft4L,
      linkText: "opensea.io",
      description: fenixCollectionDesc
    },
    {
      id: "5",
      key: fenixKey,
      tag: nft5L,
      title: fenixCollection + "[" + nft5L + "]",
      img: nft5,
      imgSmall: nft5,
      bg: "#ddd",
      client: fenixCollection,
      langages: nft5L,
      link: openseaLink + fenix + nft5L,
      linkText: "opensea.io",
      description: fenixCollectionDesc
    },
    {
      id: "6",
      key: fenixKey,
      tag: nft6L,
      title: fenixCollection + "[" + nft6L + "]",
      img: nft6,
      imgSmall: nft6,
      bg: "#ddd",
      client: fenixCollection,
      langages: nft6L,
      link: openseaLink + fenix + nft6L,
      linkText: "opensea.io",
      description: fenixCollectionDesc
    },
    {
      id: "7",
      key: fenixKey,
      tag: nft7L,
      title: fenixCollection + "[" + nft7L + "]",
      img: nft7,
      imgSmall: nft7,
      bg: "#ddd",
      client: fenixCollection,
      langages: nft7L,
      link: openseaLink + fenix + nft7L,
      linkText: "opensea.io",
      description: fenixCollectionDesc
    },
    {
      id: "8",
      key: fenixKey,
      tag: nft8L,
      title: fenixCollection + "[" + nft8L + "]",
      img: nft8,
      imgSmall: nft8,
      bg: "#ddd",
      client: fenixCollection,
      langages: nft8L,
      link: openseaLink + fenix + nft8L,
      linkText: "opensea.io",
      description: fenixCollectionDesc
    },
    {
      id: "9",
      key: wfvKey,
      tag: nft9L,
      title: wfvCollection + "[" + nft9L + "]",
      img: nft9,
      imgSmall: nft9,
      bg: "#ddd",
      client: wfvCollection,
      langages: nft9L,
      link: openseaLink + wfv + nft9L,
      linkText: "opensea.io",
      description: wfvCollectionDesc
    },
    {
      id: "10",
      key: wfvKey,
      tag: nft10L,
      title: wfvCollection + "[" + nft10L + "]",
      img: nft10,
      imgSmall: nft10,
      bg: "#ddd",
      client: wfvCollection,
      langages: nft10L,
      link: openseaLink + wfv + nft10L,
      linkText: "opensea.io",
      description: wfvCollectionDesc
    },
    {
      id: "11",
      key: wfvKey,
      tag: nft11L,
      title: wfvCollection + "[" + nft11L + "]",
      img: nft11,
      imgSmall: nft11,
      bg: "#ddd",
      client: wfvCollection,
      langages: nft11L,
      link: openseaLink + wfv + nft11L,
      linkText: "opensea.io",
      description: wfvCollectionDesc
    },
    {
      id: "12",
      key: wfvKey,
      tag: nft12L,
      title: wfvCollection + "[" + nft12L + "]",
      img: nft12,
      imgSmall: nft12,
      bg: "#ddd",
      client: wfvCollection,
      langages: nft12L,
      link: openseaLink + wfv + nft12L,
      linkText: "opensea.io",
      description: wfvCollectionDesc
    },
    {
      id: "13",
      key: wfvKey,
      tag: nft13L,
      title: wfvCollection + "[" + nft13L + "]",
      img: nft13,
      imgSmall: nft13,
      bg: "#ddd",
      client: wfvCollection,
      langages: nft13L,
      link: openseaLink + wfv + nft13L,
      linkText: "opensea.io",
      description: wfvCollectionDesc
    },
    {
      id: "14",
      key: wfvKey,
      tag: nft14L,
      title: wfvCollection + "[" + nft14L + "]",
      img: nft14,
      imgSmall: nft14,
      bg: "#ddd",
      client: wfvCollection,
      langages: nft14L,
      link: openseaLink + wfv + nft14L,
      linkText: "opensea.io",
      description: wfvCollectionDesc
    },
    {
      id: "15",
      key: wfvKey,
      tag: nft15L,
      title: wfvCollection + "[" + nft15L + "]",
      img: nft15,
      imgSmall: nft15,
      bg: "#ddd",
      client: wfvCollection,
      langages: nft15L,
      link: openseaLink + wfv + nft15L,
      linkText: "opensea.io",
      description: wfvCollectionDesc
    },
    {
      id: "16",
      key: wfvKey,
      tag: nft16L,
      title: wfvCollection + "[" + nft16L + "]",
      img: nft16,
      imgSmall: nft16,
      bg: "#ddd",
      client: wfvCollection,
      langages: nft16L,
      link: openseaLink + wfv + nft16L,
      linkText: "opensea.io",
      description: wfvCollectionDesc
    },
  ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
    {
      id: "1",
      img: nft1,
      imgSmall: nft1,
      date: "177 April",
      category: "Inspiration",
      title: "How to Own Your Audience by Creating an Email List.",
      bg: "#FCF4FF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "4",
      img: nft4,
      imgSmall: nft4,
      date: "000 April",
      category: "Inspiration",
      title: "Everything You Need to Know About Web Accessibility.",
      bg: "#EEFBFF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "2",
      img: nft2,
      imgSmall: nft2,
      date: "21 April",
      category: "Web Design",
      title: "The window know to say beside you",
      bg: "#FFF0F0",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "5",
      img: nft5,
      imgSmall: nft5,
      date: "27 April",
      category: "Inspiration",
      title: "Top 10 Toolkits for Deep Learning in 2021.",
      bg: "#FCF4FF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "3",
      img: nft3,
      imgSmall: nft3,
      date: "27 April",
      category: "Inspiration",
      title: "How to Own Your Audience by Creating an Email List.",
      bg: "#FCF4FF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "6",
      img: nft6,
      imgSmall: nft6,
      date: "27 April",
      category: "Inspiration",
      title: "Everything You Need to Know About Web Accessibility.",
      bg: "#EEFBFF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/home/homePage",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/home/about",
      icon: <FaRegUser />,
    },
    {
      id: "06",
      name: "Resume",
      link: "/home/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/home/works",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "NFTs",
      link: "/home/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "04",
      name: "Contact",
      link: "/home/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "About",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "Resume",
      link: "/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "NFTs",
      link: "/nft",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "Contact",
      link: "/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Slider image for Clients
  const sliderImg = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img1,
    img2,
    img3,
    img4,
    img5,
  ];

  // experience items for about page
  const experienceArray = [
    {
      id: "1",
      icon: icon,
      title: "Ui/Ux Design",
      des: "I really like to be the best when it comes to design, user experience etc.",
      color: "#D566FF",
      bg: "#FCF4FF",
    },
    {
      id: "2",
      icon: icon1,
      title: "App Development",
      des: "I'm Android Developper(Java, Kotlin) but maybe one day I will start to create IOS apps, time will tell",
      color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: "3",
      icon: icon2,
      title: "NFTs",
      des: "Nfts are a way to express yourself and your feeling",
      color: "#8774FF",
      bg: "#FCF4FF",
    },
    {
      id: "4",
      icon: icon3,
      title: "Photography",
      des: "Photography is just a passion, I really like to capture moments in time",
      color: "#FF6080",
      bg: "#FFF4F4",
    },
    {
      id: "5",
      icon: icon4,
      title: "Game Developpment",
      des: "I'm working on a game right now, 'Dwarf Planets' (generated in 3D by AI), it's a complicated game since it will be compatible on any game device(PC, Xbox, Nintendo Switch...), so far the game is in early developpment so I will keep you in touch with the progress",
      color: "#FF75D8",
      bg: "#FFF0F8",
    },
    {
      id: "6",
      icon: icon5,
      title: "Web Development",
      des: "My main profession is web developpment, I'm in this space for 12 years now, but there are new things to learn everyday!",
      color: "#269FFF",
      bg: "#F3FAFF",
    },
  ];

  // Resume items for Resume page
  const resumeArray = [
    {
      type: "Awards",
      icon: MdOutlineSchool,
      id: "01",
      date: "2015",
      title: "Developper of the Month",
      place: "Themeforest",
      bg: "#ddd",

      id1: "02",
      date1: "2018",
      title1: "Freelancer Rise",
      place1: "Fiverr",
      bg1: "#ddd",

      id2: "03",
      date2: "2021",
      title2: "React Top New Creators",
      place2: "Facebook",
      bg2: "#ddd",
    },
    {
      type: "Experience",
      icon: MdOutlineBusinessCenter,
      id: "04",
      date: "2013-2016",
      title: "Wordpress Theme Creator",
      place: "Themeforest.net (Envato Pty Ltd.)",
      bg: "#ddd",

      id1: "05",
      date1: "2015-2019",
      title1: "Unity 3D Dev.",
      place1: "Unity Technologies",
      bg1: "#ddd",

      id2: "06",
      date2: "2019 - present",
      title2: "NFT Creator - PixieHollow",
      place2: "opensea.io",
      bg2: "#ddd",
    },
    {
      type: "Awards",
      icon: FaAward,
      id: "07",
      date: "2015-2017",
      title: "  Graphic Designer",
      place: "Web Graphy, Los Angeles, CA",
      bg: "#FCF4FF",

      id1: "08",
      date1: "2014 - 2015",
      title1: "Jr. Web Developer",
      place1: "Creative Gigs.",
      bg1: "#FCF9F2",

      id2: "09",
      date2: "2015-2017",
      title2: "Best Freelancer",
      place2: "Fiver & Upwork Level 2 & Top Rated",
      bg2: "#FCF4FF",
    },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#FF6464",
      name: "Android Dev. (Java / Kotlin)",
      number: "90",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "Unity3D (C#)",
      number: "75",
    },
    {
      id: "03",
      color: "#5185D4",
      name: "React (Node / Javascript)",
      number: "90",
    },
    {
      id: "04",
      color: "#CA56F2",
      name: "Web Dev. (Php / Mysql)",
      number: "75",
    },
    {
      id: "05",
      color: "#777",
      name: "Solidity",
      number: "90",
    },
    
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "Phone ",
      item1: "+452 666 386",
      item2: "+452 666 386",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "clausassas@gmail.com",
      item2: "e@clausas.com",
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: iconMap,
      title: "Address ",
      item1: "Maount View, Oval",
      item2: "Road, New York, USA",
      bg: "#F2F4FF",
    },
  ];

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    menuItemTwo,
    experienceArray,
    sliderImg,
    resumeArray,
    lineArray,
    contactArray,
  };
};

export default AllData;
